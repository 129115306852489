import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as b from "../components/bootstrap.module.css";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";

let idTimeout = null;

const ProductsToc = ({ selector }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [tocClasses, setTocClasses] = useState(["toc", b.card].join(" "));
  const tocRef = useRef(null);

  

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        if (entry.isIntersecting) {
          document.querySelector(`.product-toc ul li button[data-id="${id}"]`).classList.add('active-product-button');
        } else {
          document.querySelector(`.product-toc ul li button[data-id="${id}"]`).classList.remove('active-product-button');
        }
      });
    },{
      rootMargin: '-300px 0px',
      threshold: [0.01, 0.5, 1],
    }
  );
    document.querySelectorAll('h2[id]').forEach((h2) => {
      observer.observe(h2);
    });
  }, [])

  const scroll = (id) => {

    clearTimeout(idTimeout);
    setIsOpen(false);
    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);
    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  useEffect(() => {
    if (!selector.length) {
      return;
    }
    const allH2 = document.querySelectorAll(selector);
    setListItems(
      Object.values(allH2).map(({ textContent, id }, i) => (
        <li key={i}>
          <button onClick={() => scroll(id)} className="btn-hidden" data-id={id}>
            {textContent}
          </button>
        </li>
      ))
    );
  }, [selector]);

  useEffect(() => {
    if (
      tocRef.current &&
      window.innerHeight > tocRef.current.offsetHeight + 100
    ) {
      setTocClasses(["product-toc", b.card].join(" "));
    } else {
      setTocClasses(["product-toc", b.card].join(" "));
    }
  }, [ tocRef ]);

  if (listItems.length <= 1) {
    return null;
  }

  return(
    <div>
      <div className={tocClasses}>
        <ul ref={tocRef}>{listItems}</ul>
      </div>
    </div>
  ) 
};

ProductsToc.propTypes = {
  selector: PropTypes.string.isRequired,
};

export default ProductsToc;
