import React, { useState } from 'react'
import * as b from "../components/bootstrap.module.css"
import PropTypes from "prop-types";
import Icon from "../components/icon.tsx";
import BlogContent from "../components/blog-content";

const Faq = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div 
      className={[b.colMd, isOpen ? "firm-info-box-open" : "firm-info-box"].join(" ")}
      onClick={() => setIsOpen(!isOpen)}
      role="button" 
      tabIndex={0}
      onKeyDown={setIsOpen}
    >
      <div className={[b.dFlex, b.justifyContentBetween].join(" ")}>
        <span className={[b.h4, 'font-size-18', b.my3].join(' ')}>{title}</span>
        <button className={["btn-hidden", b.ml3].join(' ')}>
          {isOpen ? (
            <Icon color="#21584D" size={35} icon="Angle-up" className="firm-icons" key="faq-up" />
          ) : (
            <Icon color="#21584D" size={35} icon="Angle-down" className="firm-icons" key="faq-down" />
          )}
        </button>
      </div>

      <BlogContent content={text} className={["faq-body", "firm-description", "blueToGray", b.pr4].join(' ')} />
    </div>
  )
}

Faq.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Faq